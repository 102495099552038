body{font-family: 'Open Sans';}

@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-ExtraBold.eot');
	src: url('OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('OpenSans-ExtraBold.woff2') format('woff2'),
		url('OpenSans-ExtraBold.woff') format('woff'),
		url('OpenSans-ExtraBold.ttf') format('truetype');
	font-weight: 800;
}

@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-Light.eot');
	src: url('OpenSans-Light.eot?#iefix') format('embedded-opentype'),
		url('OpenSans-Light.woff2') format('woff2'),
		url('OpenSans-Light.woff') format('woff'),
		url('OpenSans-Light.ttf') format('truetype');
	font-weight: 300;
}

@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-SemiBold.eot');
	src: url('OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('OpenSans-SemiBold.woff2') format('woff2'),
		url('OpenSans-SemiBold.woff') format('woff'),
		url('OpenSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
}

@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-Regular.eot');
	src: url('OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('OpenSans-Regular.woff2') format('woff2'),
		url('OpenSans-Regular.woff') format('woff'),
		url('OpenSans-Regular.ttf') format('truetype');
	font-weight: 400;
}



@font-face {
	font-family: 'Open Sans';
	src: url('OpenSans-Bold.eot');
	src: url('OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('OpenSans-Bold.woff2') format('woff2'),
		url('OpenSans-Bold.woff') format('woff'),
		url('OpenSans-Bold.ttf') format('truetype');
	font-weight: 700;
}




